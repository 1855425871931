import { ExternalPaths } from '@shared/menu-items/paths'
import { isProduction } from '@shared/pipes/config'

export const loginOptions = [
	{
		name: 'заёмщик',
		path: isProduction ? ExternalPaths.personalLk : ExternalPaths.testPersonalLk,
	},
	{
		name: 'инвестор',
		path: isProduction ? ExternalPaths.investorLk : ExternalPaths.testInvestorLk,
	},
	{
		name: 'партнёр',
		path: isProduction ? ExternalPaths.partnerLk : ExternalPaths.testPartnerLk,
	},
	{
		name: 'кредитор',
		path: isProduction ? ExternalPaths.lenderLk : ExternalPaths.testLenderLk,
	},
]
