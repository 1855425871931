import React, { SyntheticEvent } from 'react'
import { MAIN_PHONE_NUMBER, MAIN_PHONE_NUMBER_RAW } from '@constants/index'
import { FixedScreenV2 } from '@shared/components/fixed-screen-v2'
import { LoginAs } from '@shared/components/login-as'
import { MobileMenuItems, mobileMenuItems } from '@shared/components/MobileMenu/view'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'
import MenuNotepad from '@shared/svg/mobileMenu/notepad.svg'
import MenuUser from '@shared/svg/mobileMenu/user.svg'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { IStateProps } from './model'

import styles from './style.module.scss'

const popupMobileMenu = () => {
	const menu = [...mobileMenuItems]
	menu.splice(
		4,
		0,
		{
			title: 'Партнёрам',
			link: Paths.partners,
			extraAction: null,
			icon: <MenuUser />,
			subMenu: null,
		},
		{
			title: 'Журнал',
			link: ExternalPaths.companyJournal,
			extraAction: null,
			icon: <MenuNotepad />,
			subMenu: null,
		}
	)

	return menu
}

export const View = ({ onClose, enabled }: IStateProps) => {
	const router = useRouter()

	const handleClick = (e: SyntheticEvent, item: MobileMenuItems) => {
		e.stopPropagation()

		if (item.link) {
			router.push(item.link)
			return
		}
	}

	return (
		<FixedScreenV2
			transitionProps={{ in: enabled } as any}
			onClose={onClose}
			wrapperProps={{ id: 'popup-menu' }}
		>
			<div className={styles.wrap}>
				<ul className={styles.menu}>
					{popupMobileMenu().map((item, idx) => {
						if (!item.link) {
							return null
						}

						return (
							<li className={cx(styles.item)} key={idx} onClick={(e) => handleClick(e, item)}>
								{item.icon}
								{item.title}
								{item.subMenu && (
									<ul className={styles.subMenu}>
										{(item.subMenu as any[]).map((subMenu, idx) => {
											return (
												<li
													className={styles.subItem}
													key={idx}
													onClick={(e) => handleClick(e, subMenu)}
												>
													{subMenu.title}
												</li>
											)
										})}
									</ul>
								)}
							</li>
						)
					})}
				</ul>
				<LoginAs className={styles.loginAs} linksClassName={styles.loginAsLinks} />
				<div className={styles.controls}>
					<a className={styles.hotLine} href={`tel:${MAIN_PHONE_NUMBER_RAW}`}>
						<h5>{MAIN_PHONE_NUMBER}</h5>
						<span>Звонки по России бесплатно</span>
					</a>
				</div>
			</div>
		</FixedScreenV2>
	)
}
