import { usePopupMenu } from '@providers/mobile-popup-menu'

import { View } from './view'

export const PopupMenuComponent = ({ enabled }) => {
	const popupMenu = usePopupMenu()

	return View({
		enabled,
		onClose: () => popupMenu.setVisibleStatus(false),
	})
}
