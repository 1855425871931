import { Paths } from '@shared/menu-items/paths'

import { MenuItem } from './model'
import { topMenuItems } from './top-menu-items'

export const popupMenuItems: MenuItem[] = ([] as MenuItem[]).concat(topMenuItems, [
	{
		name: 'Контакты',
		path: Paths.contacts,
	},
])
