import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Sizes } from '@constants/sizes'
import { useWindowSize } from '@shared/hooks/use-window-size'
import { withCheckClient } from '@shared/pipes/client'

import { View as PopupLoginOptions } from './view'

const setDataAttrMobilePopup = withCheckClient((flag: boolean) => {
	document.documentElement.setAttribute('data-popup-open', String(flag))
})

interface PopupMenuMethods {
	show(): void
}

const Context = React.createContext<PopupMenuMethods | undefined>(undefined)

const maxWidthToShow = Sizes.mobile

export const MobileLoginOptionsProvider = ({ children }: PropsWithChildren) => {
	const [show, setVisibleStatus] = useState(false)
	const { width } = useWindowSize()

	useEffect(() => {
		if (width! > maxWidthToShow && show) {
			setVisibleStatus(false)
		}
	}, [show, width])

	useEffect(() => {
		setDataAttrMobilePopup(show)
	}, [show])

	const handleClose = () => setVisibleStatus(false)

	const handleShow = () => setVisibleStatus(true)

	return (
		<Context.Provider value={{ show: handleShow }}>
			<PopupLoginOptions onClose={handleClose} enabled={width! <= maxWidthToShow && show} />
			{children}
		</Context.Provider>
	)
}

export const useMobileLoginOptions = () => React.useContext(Context) as PopupMenuMethods
