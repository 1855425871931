import React from 'react'
import FullLogoSVG from '@shared/svg/full-logo.svg'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ className }: IStateProps) => (
	<div className={cx(styles.svgWrap, className)}>
		<FullLogoSVG className={styles.svg} />
	</div>
)
