import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import { StateProps } from './model'

import styles from './style.module.scss'

export const View = ({ children, ...buttonProps }: PropsWithChildren<StateProps>) => (
	<button {...buttonProps} className={cx(styles.button, buttonProps.className)}>
		{children}
	</button>
)
