import { useEffect } from 'react'
import { runOnClient } from '@shared/pipes/client'
import { useRouter } from 'next/router'

export const useRouterChangeStart = (handler: (newUrl: string) => void) => {
	const router = useRouter()

	useEffect(() => {
		runOnClient(() => {
			router.events.on('routeChangeStart', handler)
		})

		return () => {
			runOnClient(() => {
				router.events.off('routeChangeStart', handler)
			})
		}
	}, [handler])
}

export const useRouterChangeEnd = (handler: (newUrl: string) => void) => {
	const router = useRouter()

	useEffect(() => {
		runOnClient(() => {
			router.events.on('routeChangeComplete', handler)
		})

		return () => {
			runOnClient(() => {
				router.events.off('routeChangeComplete', handler)
			})
		}
	}, [handler])
}
