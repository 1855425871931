import React, { Fragment, PropsWithChildren } from 'react'
import { MAIN_PHONE_NUMBER } from '@constants/index'
import { FixedScreen } from '@shared/components/fixed-screen'
import { LoginAs } from '@shared/components/login-as'
import { Socials } from '@shared/components/socials'
import { popupMenuItems } from '@shared/menu-items/popup-menu-items'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { IStateProps } from './model'

import styles from './style.module.scss'

const phone = MAIN_PHONE_NUMBER

export const View = ({ onClose, enabled }: PropsWithChildren<IStateProps>) => {
	const { pathname } = useRouter()

	const isWowPage = pathname === '/wow'

	return (
		<FixedScreen
			transitionProps={{ in: enabled } as any}
			onClose={onClose}
			wrapperProps={{ id: 'popup-menu' }}
		>
			<nav className={styles.menu}>
				{isWowPage
					? null
					: popupMenuItems.map((item, i) => (
							<Fragment key={i}>
								<div className={styles.menuItem}>
									<Link key={item.name} href={item.path!}>
										{item.name}
									</Link>
								</div>
								{Array.isArray(item.items) &&
									item.items.map((subItem, i) => (
										<div className={cx(styles.menuItem, styles.menuSubItem)} key={`${subItem.name}-${i}`}>
											<Link key={subItem.name} href={subItem.path!}>
												{subItem.name}
											</Link>
										</div>
									))}
							</Fragment>
					  ))}
			</nav>
			<div className={styles.controls}>
				<LoginAs className={styles.loginAs} linksClassName={styles.loginAsLinks} />
				<div className={styles.socialsAndLogin}>
					<Socials withoutOD />
					<a className={styles.hotLine} href={`tel:${phone.replace(/\s/g, '')}`}>
						<h5>{phone}</h5>
						<span>Звонки по России бесплатно</span>
					</a>
				</div>
			</div>
		</FixedScreen>
	)
}
