import React from 'react'
import { usePopupMenu } from '@providers/mobile-popup-menu'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'
import MenuAbout from '@shared/svg/mobileMenu/about.svg'
import MenuBanks from '@shared/svg/mobileMenu/banks.svg'
import MenuBurger from '@shared/svg/burger.svg'
import MenuEconomy from '@shared/svg/mobileMenu/economy.svg'
import MenuLoan from '@shared/svg/mobileMenu/loan.svg'
import cx from 'classnames'
import { useRouter } from 'next/router'

import { scrollToApplicationForm } from '../forms/application/cross'

import styles from './style.module.scss'

export type MobileMenuItems = (typeof mobileMenuItems)[number]

export const mobileMenuItems = [
	{
		title: 'Кредиты',
		link: Paths.root,
		extraAction: scrollToApplicationForm,
		icon: <MenuLoan />,
		subMenu: null,
		//  [
		// 	{
		// 		title: 'Ипотека',
		// 		link: Paths.root,
		// 	},
		// 	{
		// 		title: 'Потребительский кредит на любые цели',
		// 		link: Paths.root,
		// 	},
		// 	{
		// 		title: 'Кредит под залог авто',
		// 		link: Paths.productRealEstate,
		// 	},
		// 	{
		// 		title: 'Кредит под залог квртиры',
		// 		link: Paths.productApartment,
		// 	},
		// ],
	},
	{
		title: 'Сбережения',
		link: Paths.invest,
		extraAction: null,
		icon: <MenuEconomy />,
		subMenu: null,
	},
	{
		title: 'Меню',
		link: null,
		extraAction: null,
		icon: <MenuBurger />,
		subMenu: null,
	},
	{
		title: 'Банкам',
		link: ExternalPaths.banks,
		extraAction: null,
		icon: <MenuBanks />,
		subMenu: null,
	},
	{
		title: 'О компании',
		link: Paths.about,
		extraAction: null,
		icon: <MenuAbout />,
		subMenu: null,
	},
]

export const View = () => {
	const router = useRouter()
	const popupMenu = usePopupMenu()

	const handleClick = (item: MobileMenuItems) => {
		if (item.link && item.link !== router.pathname) {
			router.push(item.link)
			return
		}
		if (item.link === router.pathname && item.extraAction) {
			item.extraAction()
			return
		}

		if (!item.link) {
			popupMenu.setVisibleStatus(true)
		}
	}

	return (
		<div className={cx(styles.wrap, popupMenu.isShow() && styles.hidden)}>
			<ul>
				{mobileMenuItems.map((item, idx) => {
					return (
						<li
							className={cx(styles.item, item.link === router.pathname && styles.active)}
							key={idx}
							onClick={() => handleClick(item)}
						>
							{item.icon}
							{item.title}
						</li>
					)
				})}
			</ul>
		</div>
	)
}
