import { MenuItem } from '@shared/menu-items/model'
import { ExternalPaths, Paths } from '@shared/menu-items/paths'

export const topMenuItems: MenuItem[] = [
	{
		name: 'Кредиты',
		path: Paths.root,
	},
	{
		name: 'Сбережения',
		path: Paths.invest,
	},
	{
		name: 'Банкам',
		path: ExternalPaths.banks,
	},
	{
		name: 'Партнёрам',
		path: Paths.partners,
	},
	{
		name: 'О компании',
		path: Paths.about,
		items: [
			// 	{
			// 		name: 'Команда',
			// 		path: Paths.notFound,
			// 	},
			// {
			// 	name: 'Платформа',
			// 	path: Paths.platform,
			// },
			// 	{
			// 		name: 'Мы в СМИ',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Работа у нас',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Структура платформы',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Вакансии',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Разработчикам',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Реферальная программа',
			// 		path: Paths.notFound,
			// 	},
			// 	{
			// 		name: 'Корпоративная жизнь',
			// 		path: Paths.notFound,
			// 	},
		],
	},
	{
		name: 'Журнал',
		path: ExternalPaths.companyJournal,
	},
]
