import { useEffect } from 'react'
import { isClient } from '@shared/utils/is-client'

export const useWindowScrollTop = (
	handler: (scrollTop: number) => void,
	deps?: any[],
	execHandlerOnInit?: boolean
) => {
	const handleScroll = () => {
		handler(window.pageYOffset || document.documentElement.scrollTop)
	}

	useEffect(() => {
		if (!isClient) {
			return handler(0)
		}

		if (execHandlerOnInit) {
			handleScroll()
		}
	}, [])

	useEffect(() => {
		if (!isClient) {
			return handler(0)
		}

		document.addEventListener('scroll', handleScroll)

		return () => {
			document.removeEventListener('scroll', handleScroll)
		}
	}, deps)
}
