import { PropsWithChildren } from 'react'
import { Transition } from 'react-transition-group'
import { Container } from '@shared/components/container'
import { FullLogo } from '@shared/components/logo'
import { Burger } from '@shared/components/top-menu/shared/burger'
import cx from 'classnames'

import { StateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	children,
	transitionProps,
	wrapperProps,
	onClose,
}: PropsWithChildren<StateProps>) => {
	return (
		<Transition mountOnEnter unmountOnExit timeout={{ enter: 0, exit: 300 }} {...transitionProps}>
			{(state) => {
				return (
					<div className={cx(styles.wrap, styles[state])} {...wrapperProps}>
						<div className={styles.overlay} onClick={onClose} tabIndex={-1}>
							<div className={styles.containerWrap}>
								<Container className={styles.container}>
									<div className={styles.copyMenu}>
										<FullLogo className={styles.logo} />
										<div className={styles.rightControls}>
											<Burger onClick={onClose} active={true} />
										</div>
									</div>
									{children}
								</Container>
							</div>
						</div>
					</div>
				)
			}}
		</Transition>
	)
}
