import React, { ComponentType } from 'react'
import { ExternalPaths } from '@shared/menu-items/paths'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'
import Vk from '@shared/svg/vk.svg'
import Od from '@shared/svg/od.svg'

interface Social {
	alt: string
	href: string
	IconComponent: ComponentType<any>
}

export const socials: Social[] = [
	// {
	// 	alt: 'Фейсбук',
	// 	href: ExternalPaths.companyFacebookPage,
	// 	IconComponent: SvgImages.Facebook,
	// },
	{
		alt: 'ВКонтакте',
		href: ExternalPaths.companyVkPage,
		IconComponent: Vk,
	},
	{
		alt: 'Одноклассники',
		href: ExternalPaths.companyOdPage,
		IconComponent: Od,
	},
	// {
	// 	alt: 'Инстаграм',
	// 	href: ExternalPaths.companyInstagram,
	// 	IconComponent: SvgImages.Instagram,
	// },
]

export const View = ({ className, withoutOD }: IStateProps) => (
	<div className={cx(styles.wrap, className)}>
		{socials.map(({ alt, href, IconComponent }) => {
			return withoutOD && alt === 'Одноклассники' ? null : (
				<a
					data-gtm-element='footer-soc-icon-click'
					aria-label={alt}
					key={href}
					className={styles.item}
					href={href}
					rel='noopener noreferrer'
					target='_blank'
				>
					<IconComponent />
				</a>
			)
		})}
	</div>
)
