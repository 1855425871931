import React, { PropsWithChildren, useEffect, useState } from 'react'
import { Sizes } from '@constants/sizes'
import { useWindowSize } from '@shared/hooks/use-window-size'
import { withCheckClient } from '@shared/pipes/client'

import { PopupMenuComponent } from './popup-menu-component'
import { PopupMenuComponentV2 } from './popup-menu-component-v2'

const setDataAttrMobilePopup = withCheckClient((flag: boolean) => {
	document.documentElement.setAttribute('data-popup-open', String(flag))
})

interface PopupMenuMethods {
	setVisibleStatus(status: boolean): void
	isShow(): boolean
}

const PopupMenuContext = React.createContext<PopupMenuMethods | undefined>(undefined)

const maxWidthToShow = Math.max(
	...[Sizes.fixedMenuShowNavBreakpoint, Sizes.topMenuShowNavBreakpoint]
)

export const MobileMenuProvider = ({ children }: PropsWithChildren) => {
	const [show, setVisibleStatus] = useState(false)
	const { width = 0 } = useWindowSize()

	useEffect(() => {
		if (width > maxWidthToShow && show) {
			setVisibleStatus(false)
		}
	}, [show, width])

	useEffect(() => {
		setDataAttrMobilePopup(show)
	}, [show])

	const methods: PopupMenuMethods = {
		isShow(): boolean {
			return show
		},
		setVisibleStatus(status: boolean) {
			setVisibleStatus(status)
		},
	}

	return (
		<PopupMenuContext.Provider value={methods}>
			<PopupMenuComponent enabled={width <= maxWidthToShow && width > Sizes.mobile && show} />
			<PopupMenuComponentV2 enabled={width <= Sizes.mobile && show} />
			{children}
		</PopupMenuContext.Provider>
	)
}

export const usePopupMenu = () => React.useContext(PopupMenuContext) as PopupMenuMethods
